type Props = {
  name: "close" | "angleLeft" | "submit";
};

const Icon = ({ name }: Props) => {
  const getTitle = {
    close: "close",
    angleLeft: "left angle",
    submit: "submit",
  };
  const getPath = {
    close: (
      <path d="M14.2,12l9.4-9.4c0.6-0.6,0.6-1.5,0-2.1s-1.5-0.6-2.1,0L12,9.9L2.6,0.5C2-0.1,1-0.1,0.4,0.5s-0.6,1.5,0,2.1L9.9,12l-9.4,9.4 c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1,0.4s0.7-0.1,1-0.4l9.4-9.4l9.4,9.4c0.3,0.3,0.7,0.4,1,0.4c0.4,0,0.7-0.1,1-0.4 c0.6-0.6,0.6-1.5,0-2.1L14.2,12z" />
    ),
    angleLeft: (
      <path d="M9.3,12l9.5-9.4C18.9,2.4,19,2.2,19,2s-0.1-0.4-0.2-0.6l-1.2-1.2C17.4,0.1,17.2,0,17,0s-0.4,0.1-0.6,0.2L5.2,11.4 C5.1,11.6,5,11.8,5,12c0,0.2,0.1,0.4,0.2,0.6l11.2,11.2c0.2,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2l1.2-1.2 c0.1-0.2,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6L9.3,12z" />
    ),
    submit: (
      <path d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z" />
    ),
  };
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" focusable="false">
      <title>{getTitle[name]}</title>
      {getPath[name]}
    </svg>
  );
};

export default Icon;
